import React, { Component } from 'react';
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import { Gemstones } from '../../utils/gemstones'
import WearWithPicker from '../wear_with_picker'

const Title = styled.h3`
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
`
const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: space-around;
  .thumbnail {
    &:last-child {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`

class WearWith extends Component {
  render() {

    const { title, description, tags, meta } = this.props;
    let gemstone;

    tags.map(tag => {
      Gemstones.map(gem => {
        if(gem.name === tag) {
          gemstone = gem;
        }
      })
    })

    return (
      <StaticQuery
        query={graphql`
        query WearWithQuery {
          products: allShopifyProduct(
            filter: {tags: {ne: "Hidden on /shop"}}, sort: { fields: [title], order: ASC }
          ) {
            edges {
              node {
                id
                handle
                title
                tags
                description
                productType
                shopifyId
                metafields {
                  value
                  key
                }
                variants {
                  shopifyId
                  title
                  price
                  availableForSale
                }
                images {
                  id
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 910) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        `}
        render={({ products }) => (
          <>
          <Title>Wear With</Title>
            <ThumbnailContainer>
              <WearWithPicker meta={meta} products={products.edges} title={this.props.title} description={description} tags={tags} />
            </ThumbnailContainer>
          </>
        )}
      />
    );
  }
}

export default WearWith;
