import React, { Component, useState, useRef, useContext, useEffect, createContext } from 'react'
import useScrollSpy from 'react-use-scrollspy'
import useVisibility from 'react-intersection-visible-hook'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import styled, { keyframes } from 'styled-components'
import SEO from '../../components/seo'
import Carousel from 'nuka-carousel'
import ContainerProduct from '../../components/container_product'
import ProductGemstone from '../../components/product_gemstone'
import WearWith from '../../components/wear_with'
import StoreContext from '../../context/StoreContext'
import Image from 'gatsby-image'
import ComingSoon from '../../images/coming_soon.png'
import { Link } from 'gatsby'
import btoa from 'btoa'

import closePopup from '../../images/newsletter_x.svg'

let letterKey;
let letterVariant;

const ImageContainer = styled.div`
  max-width: calc(100% - 275px);
  max-height: 100vh;
  margin-bottom: 2rem;
  text-align: center;
  @media (max-width: 768px) {
    display: none;
  }
`

const MobileImageContainer = styled.div`
  width: 100%;
  /* max-height: 100vh; */
  margin-bottom: 3rem;
  text-align: center;
`

const VariantImage = styled.img`
  width: 100%;
`

const ProductImage = styled.img`
  max-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
`

const WearwithContainer = styled.div`
  width: 100%;
  margin: 0px 0px 6rem 0px;
  max-width: calc(100% - 270px);
  @media (max-width: 768px) {
    max-width: none;
    margin: 0px 0px 2rem 0px;
  }
`

const ImageContainerWrapper = styled.div`
  min-height: 100vh;
  @media (max-width: 768px) {
      min-height: 0;
  }
`

const ProductDetailsContainer = styled.div`
  opacity: 1;
  transition: all 0.3s ease-in-out;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 10;
  &.hidden {
    opacity: 0;
    @media (max-width: 768px) {
      opacity: 1;
    }
  }
  &.visible {
    opacity: 1;
  }
  @media (max-width: 768px) {
    position: static;
    height: auto;
  }
`

const ProductShareContainerMobile = styled.div`
  position: fixed;
  left: 0px;
  padding-left: 30px;
  bottom: 2rem;
  width: 275px;
  opacity: 1;
  transition: all 0.25s ease-in-out;
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: static;
    text-align: center;
    padding: 0px;
    margin: 1rem 0px 2rem 0px;
    width: auto;
  }
  &.hidden {
    opacity: 0;
    @media (max-width: 768px) {
      opacity: 1;
    }
  }
  .visible {
    opacity: 1;
  }
  ul {
    list-style: none;
    li {
      display: inline;
      cursor: pointer;
      margin-right: 20px;
      &.active {
        font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
        font-style: italic;
      }
      a {
        color: #000;
        text-decoration: none;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
`

const ProductShareContainer = styled.div`
  position: fixed;
  left: 0px;
  padding-left: 30px;
  bottom: 2rem;
  width: 275px;
  opacity: 1;
  transition: all 0.25s ease-in-out;
  z-index: 999999;
  @media (max-width: 768px) {
    display: none;
  }
  &.hidden {
    opacity: 0;
    @media (max-width: 768px) {
      opacity: 1;
    }
  }
  .visible {
    opacity: 1;
  }
  ul {
    list-style: none;
    li {
      display: inline;
      cursor: pointer;
      margin-right: 20px;
      &.active {
        font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
        font-style: italic;
      }
      a {
        color: #000;
        text-decoration: none;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
`

const Counter = styled.div`
  float: right;
  @media (max-width: 768px) {
    display: none;
  }
`

const TotalCount = styled.span`
  color: #A19C95;
  @media (max-width: 768px) {
    display: none;
  }
`

const ProductDetails = styled.div`
  width: 300px;
  float: right;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  padding: 16px 30px 1rem 30px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }

  button {
    background-color: #ffffff;
    margin-bottom: 0px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 1px solid ${props => props.theme.brown3};
    border-left: none;
    border-right: none;
    border-bottom: none;
    font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    transition: all 0.25s ease-in-out;
    font-size: 12px;
    &.oos {
      cursor: default;
      &:hover {
        background-color: #fff;
      }
    }
    @media (max-width: 768px) {
      margin-top: 1rem;
      border-bottom: 1px solid ${props => props.theme.brown3};
    }
    button:disabled, button[disabled]{
      border: 1px solid #999999;
      background-color: #fff;
      color: #666666;
    }
    cursor: pointer;
    &:hover {
      border-top-color: #EEE9E2;
      background-color: #EEE9E2;
    }
  }
`

const LetterSelector = styled.input`
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  background-color: transparent;
  padding-bottom: 2px;
  text-align: center;
  text-transform: uppercase;
  color: #9C978F;
  border-bottom: 1px solid #9C978F;
`

const ProductTitle = styled.h3`
  margin-bottom: 0.5rem;
  font-size: 13px;
  @media (max-width: 768px) {
    text-align: center;
  }
`

const slideDown = (props) => keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`

const slideUp = (props) => keyframes`
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(-10px);
    opacity: 1;
  }
`

const Share = styled.div`
  margin-bottom: 2rem;
  display: none;
  &.closed {
    height: 0px;
    overflow: hidden;
    animation-delay: 0.25s;
    animation: ${slideDown} 250ms both;
  }
  &.open {
    display: block;
    animation-delay: 0.25s;
    animation: ${slideUp} 250ms both;
  }
`
const Care = styled.div`
  margin-bottom: 3rem;
  display: none;
  p {
    @media (max-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &.closed {
    height: 0px;
    overflow: hidden;
    animation-delay: 0.25s;
    animation: ${slideDown} 250ms both;
  }
  &.open {
    display: block;
    background-color: #fff;
    animation-delay: 0.25s;
    animation: ${slideUp} 250ms both;
  }
`

const Desktop = styled.div`
  display: block;
  button {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    button {
      width: 100%;
    }
  }
`
const MobilePreorder = styled.p`
  font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  font-size: 13px;
  margin-top: 1rem;
  padding-right: 0px !important;
`
const Close = styled.div`
  float: right;
  margin-right: 5px;
  margin-top: 5px;
`

const DesktopPreorder = styled.div`
  transition: all 0.25s ease-in-out;
  display: none;
  button {
    width: 100%;
    background-color: transparent;
  }
  p {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    margin-top: 10px;
  }
  &.closed {
    opacity: 0;
    display: none;
  }
  &.open {
    position: absolute;
    background-color: #F8F7F6;
    margin-bottom: 20px;
    right: 30px;
    height: 200px;
    transform: translateY(-96px);
    padding: 10px;
    z-index: 99;
    opacity: 1;
    display: block;
  }
`

const Slide = styled.div`
  display: flex;
  background-color: #fff;
  /* height: 80vh; */
  width: 100vw;
  height: 70vh;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  img {
    /* max-height: 320px; */
    object-fit: contain !important;
    width: 100vw;
    height: auto;
    @media (max-width: 768px) {
      max-height: none;
    }
  }
  .left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    @media (max-width: 768px) {
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .photo-wrapper {
      width: 230px;
      float: right;
      margin-right: 80px;
      @media (max-width: 768px) {
          float: none;
          max-width: 80vw;
          margin-left: auto;
          margin-right: auto;
      }
    }
  }
  .right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 768px) {
        width: 80vw;
        margin-top: 10px;
    }
    p {
      margin-left: 80px;
      max-width: 300px;
      @media (max-width: 768px) {
          max-width: 80vw;
      }
    }
  }
`

const ProductPrice = styled.h4`
  margin-bottom: 2rem;
  color: #000;
  font-size: 13px;
  font-weight: normal;
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 1rem;
  }
`

const ProductWrapper = styled.div`
  .slider-list {
    touch-action: initial !important;
  }
`

const WearWithWrapper = styled.div`
  margin-top: 100px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #888;
  display: inline-block;
  margin-right: 10px;
`

const SlideNum = styled.div`
  text-align: center;
  transform: translateY(-2rem);
  color: #9C978F;
  span {
    color: #3C3C3B;
  }
`

const Table = styled.table`
  tr:first-child {
    /* border: 1px solid green; */
  }
  td {
    padding-bottom: 0.5rem;
    vertical-align: top;
    span {
      margin-right: 0.7rem;
    }
    span {
      color: ${props => props.theme.text_color};
      opacity: 0.5;
      cursor: pointer;
      &.active {
        opacity: 1;
        color: ${props => props.theme.link_color};
      }
    }
    &.label {
      width: 35%;
    }
  }
`

const PreOrderButton = styled.img`
  max-width: 120px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
`

const ProductFull = props => {

  const { product } = props;
  const [activeVariant, setVariant] = useState(props.product.variants[0])
  const [available, setAvailable] = useState(activeVariant.availableForSale)
  const [activeSlide, setActiveSlide] = useState(0);
  const [letter, setLetter] = useState('A');
  const [cartText, setCartText] = useState('ADD TO CART');
  const [preordercartText, setPreorderCartText] = useState('PRE ORDER');

  // if(activeVariant.sku === 'preorder') {
  //   setCartText('PRE ORDER');
  // }

  useEffect(() => {
    letterKey = false;
    console.log(activeVariant.sku.toLowerCase());
    if(!available) {
      if(activeVariant.sku.toLowerCase() === 'coming soon') {
        setCartText('COMING SOON');
      } else {
        setCartText('OUT OF STOCK');
      }
    } else {
      setCartText('ADD TO CART');
    }


  }, []);

  // this is to fade out the right column once wear with scrolls into view
  const nodeRef = useRef(null);
  let wearwithVisibility;
  if (typeof window !== `undefined`) {
    wearwithVisibility = useVisibility(nodeRef);
  }  else {
    wearwithVisibility = { isIntersecting: false }
  }

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -300,
  });

  const handleAddToCart = (activeVariant) => {
    context.togglePreorder(false);
    // console.log('adding shopify id',activeVariant.shopifyId);
    context.addVariantToCart(activeVariant.shopifyId, 1);
    console.log('sku',activeVariant.sku);
    if(activeVariant.sku === 'preorder') {
      setPreorderCartText('ADDED TO CART');
    } else {
      setCartText('ADDED TO CART');
    }

  }

  const handleVariantChange = selectedVariant => {
    if(selectedVariant.availableForSale) {
      setAvailable(true);
      setCartText('ADD TO CART');
      setVariant(selectedVariant);
    } else {
      setAvailable(false);
      if(selectedVariant.sku.toLowerCase() === 'coming soon') {
        setCartText('COMING SOON');
      } else {
        setCartText('OUT OF STOCK');
      }
      setVariant(selectedVariant);
    }
  }

  const handleLetterChange = (event) => {
    if(event.target.value.match(/[A-Z]/i) || event.target.value === '') {
      if(event.target.value != '') {
        setLetter(event.target.value.toUpperCase());
        product.variants.map((variant, index) => {
          if(event.target.value.toUpperCase() === variant.title) {
            letterKey = event.target.value.toUpperCase();
            letterVariant = variant;
            if(variant.availableForSale) {
              setAvailable(true);
              setCartText('ADD TO CART');
            } else {
              setAvailable(false);
              if(variant.sku.toLowerCase() === 'coming soon') {
                setCartText('COMING SOON');
              } else {
                setCartText('OUT OF STOCK');
              }
            }
            setVariant(letterVariant);
          }
        });

        // console.log(letterKey, letterVariant);

      }
    }
  }

  const {
    product: {
      title,
      handle,
      productType,
      metafields,
      description,
      tags,
      variants: [firstVariant],
      images: [firstImage]
    }
  } = props;


  const wear_with_json = metafields !== undefined && metafields.length > 0 && metafields[0].value.split('gid').length - 1 === 3 ? JSON.parse(metafields.filter(m => m.key === 'wear_with')[0].value) : [];
  const wear_with_meta_ids_base64 = wear_with_json.length === 3 ? wear_with_json.map(p => {
    // console.log(handle, p);
    return (
      btoa(p)
    )
  }) : false;

  const { price } = firstVariant;
  const context = useContext(StoreContext);
  const [careVisible, toggleCare] = useState(false);
  const [shareVisible, toggleShare] = useState(false);
  const [preorderpopup, togglePreorder] = useState(false);
  const hasVariants = props.product.variants.length > 1;
  const showLetterSelector = product.variants[0].title === 'A' ? true : false;

  const shareUrl = 'http://www.theodorawarre.co.uk/' + props.product.handle;
  const shareUrlTwitter = 'https://twitter.com/home?status='+shareUrl+' '+props.product.title;
  const shareUrlFacebook = 'https://www.facebook.com/sharer/sharer.php?u='+shareUrl;
  const shareUrlPinterest = 'https://pinterest.com/pin/create/button/?url='+shareUrl+'&media=&description='+props.product.title;
  const shareUrlTumblr = 'https://www.tumblr.com/widgets/share/tool?shareSource=legacy&canonicalUrl='+encodeURI(shareUrl)+'&posttype=link'

  const PhotoCount = showLetterSelector ? 3 : product.images.length;

  // console.log(product.images[0]);

  return (
    <ContainerProduct>
      <SEO title={title} keywords={[product.productType, `Theodora`, `Warre`]} />
      <Container fluid style={{ paddingLeft: '0px', paddingRight: '0px'}}>
      <ProductWrapper>
      <Mobile>
        <Row>
        <ProductDetails>
          <ProductTitle>{title}</ProductTitle>
          <ProductPrice>
            £{price}
          </ProductPrice>
        </ProductDetails>
      </Row>
      <Carousel
        slidesToShow={1}
        wrapAround={true}
        swiping={true}
        dragging={true}
        speed={800}
        renderBottomCenterControls={null}
        enableKeyboardControls={true}
        autoplayInterval={7000}
        autoplay={false}
        transitionMode="fade"
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        afterSlide={slideIndex => setActiveSlide(slideIndex)}
        >

        <Slide key="first">
        { letterKey
          ? <MobileImageContainer><ProductImage src={letterVariant.image.originalSrc} /></MobileImageContainer>
          : <MobileImageContainer><ProductImage src={product.images[0].localFile.childImageSharp.fluid.src} /></MobileImageContainer>
        }
        </Slide>
        {
          product.images.slice(0,3).map((image, index) => {
            if(index !== 0) {
              let refIndex = index+1;
              return (
                <Slide key={index}>
                  <MobileImageContainer><ProductImage src={image.localFile.childImageSharp.fluid.src} /></MobileImageContainer>
                </Slide>
              )
            }
          })
        }
        </Carousel>
        <SlideNum><span>{activeSlide+1}</span> / {PhotoCount}</SlideNum>
      </Mobile>


      <Row>
        <Col>

        <ProductShareContainer className={wearwithVisibility.isIntersecting ? 'hidden' : 'visible'}>
          <Share className={context.shareVisible ? 'open' : 'closed'}>
            <ul>
              <li><a target="_blank" href={shareUrlFacebook}>Facebook</a></li>
              <li><a target="_blank" href={shareUrlTwitter}>Twitter</a></li>
              <li><a target="_blank" href={shareUrlPinterest}>Pinterest</a></li>
              <li><a target="_blank" href={shareUrlTumblr}>Tumblr</a></li>
            </ul>
          </Share>
          <Care className={context.careVisible ? 'open' : 'closed'}>
            <p>Your Theodora Warre jewellery is made with care and love please treat it as such Avoid water and body lotions to prolong it's longevity.</p>
            <p>Colour variations and inclusions in stones may occur and are part of their beauty. Silver jewellery does tarnish we advise cleaning your jewellery with a silver polishing cloth.</p>
          </Care>
          <Counter><span className="active-count">{activeSection+1}</span><TotalCount> / {PhotoCount}</TotalCount></Counter>
          <ul>
            <li className={context.shareVisible ? 'active' : ''} onClick={(e) => context.toggleShare(!context.shareVisible)}>Share</li>
            <li className={context.careVisible ? 'active' : ''} onClick={(e) => context.toggleCare(!context.careVisible)}>Care</li>
            <li><a target="_blank" state={{ informationOpen: true, menuOpen: false }} activeclassname="active" to="/shipping-returns">Shipping</a></li>
          </ul>
        </ProductShareContainer>

        <ProductDetailsContainer className={wearwithVisibility.isIntersecting ? 'hidden' : 'visible'}>
          <ProductDetails>
            <Desktop>
              <ProductTitle>{title}</ProductTitle>
              <ProductPrice>
                £{price}
              </ProductPrice>
            </Desktop>

            <Table>
              <tbody>

              { description &&
                <tr><td className="label">Description:</td><td className="value">{description}<br /><br /><br /></td></tr>
              }

              { product.tags &&
                <ProductGemstone tags={product.tags} />
              }

              { showLetterSelector &&
                <>
                  <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                  <tr>
                    <td className="label">Letter:</td>
                    <td>
                      <LetterSelector maxLength="1" pattern="[A-Z]{1}" type="text" onChange={(event) => {handleLetterChange(event)}} />
                    </td>
                  </tr>
                  <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                </>
              }

              { product.variants !== undefined // sizes
                && product.variants.length > 1
                && product.variants.length < 4 &&
                <tr>
                <td className="label">Size Select:</td>
                <td className="value">
                { product.variants.map((v, index) => {
                  return(
                    <span key={index} className={v.shopifyId === activeVariant.shopifyId ? 'active' : ''}>
                      <a onClick={(e) => {handleVariantChange(v)}}>{v.title}</a>
                    </span>
                  )
                })}
                </td></tr>
              }

              </tbody>
            </Table>

            {
              activeVariant.sku === 'preorder' &&
              <>
              <Desktop>
                <DesktopPreorder className={context.preorderpopup ? 'open' : 'closed'}>
                  <Close><img src={closePopup} onClick={(e) => context.togglePreorder(!context.preorderpopup)} /></Close>
                  <p><i>Please note</i><br />Pre order for this item will take 6 to 8 weeks.</p>
                  <button onClick={() => handleAddToCart(activeVariant)}>PROCEED WITH PRE ORDER</button>
                </DesktopPreorder>
                <button onClick={(e) => context.togglePreorder(!context.preorderpopup)}>{preordercartText}</button>
              </Desktop>
              <Mobile>
              <button onClick={() => handleAddToCart(activeVariant)}>{preordercartText}</button>
                <MobilePreorder><i>Please note</i><br />Pre order for this item will take 6 to 8 weeks.</MobilePreorder>
              </Mobile>
              </>
            }

            {
              activeVariant.sku.toLowerCase() === 'coming soon' &&
              <PreOrderButton src={ComingSoon} alt="Coming Soon" />
            }

            {
              activeVariant.sku != 'preorder' && activeVariant.sku.toLowerCase() != 'coming soon' &&
              <button disabled={!available} className={available ? 'forsale' : 'oos'} onClick={() => handleAddToCart(activeVariant)}>{cartText}</button>
            }

          </ProductDetails>
          </ProductDetailsContainer>

          <ImageContainerWrapper>
          { letterKey
            ? <ImageContainer ref={sectionRefs[0]}><ProductImage src={letterVariant.image.originalSrc} /></ImageContainer>
            : <ImageContainer ref={sectionRefs[0]}><ProductImage src={product.images[0].localFile.childImageSharp.fluid.src} /></ImageContainer>
          }
          </ImageContainerWrapper>
        </Col>
      </Row>

      {
        product.images.slice(0,3).map((image, index) => {
          if(index !== 0) {
            let refIndex = index+1;
            return (
              <Row key={index}>
                <Col>
                    <ImageContainer ref={sectionRefs[index]}><ProductImage src={image.localFile.childImageSharp.fluid.src} /></ImageContainer>
                </Col>
              </Row>
            )
          }
        })
      }

      <ProductShareContainerMobile>
        <Share className={context.shareVisible ? 'open' : 'closed'}>
          <ul>
            <li><a target="_blank" href={shareUrlFacebook}>Facebook</a></li>
            <li><a target="_blank" href={shareUrlTwitter}>Twitter</a></li>
            <li><a target="_blank" href={shareUrlPinterest}>Pinterest</a></li>
            <li><a target="_blank" href={shareUrlTumblr}>Tumblr</a></li>
          </ul>
        </Share>
        <Care className={context.careVisible ? 'open' : 'closed'}>
          <p>Your Theodora Warre jewellery is made with care and love please treat it as such Avoid water and body lotions to prolong it's longevity.</p>
          <p>Colour variations and inclusions in stones may occur and are part of their beauty. Silver jewellery does tarnish we advise cleaning your jewellery with a silver polishing cloth.</p>
        </Care>
        <Counter><span className="active-count">{activeSection+1}</span><TotalCount> / {PhotoCount}</TotalCount></Counter>
        <ul>
          <li className={context.shareVisible ? 'active' : ''} onClick={(e) => context.toggleShare(!context.shareVisible)}>Share</li>
          <li className={context.careVisible ? 'active' : ''} onClick={(e) => context.toggleCare(!context.careVisible)}>Care</li>
          <li><a target="_blank" state={{ informationOpen: true, menuOpen: false }} activeclassname="active" href="/shipping-returns">Shipping</a></li>
        </ul>
      </ProductShareContainerMobile>
      </ProductWrapper>
      <WearWithWrapper ref={nodeRef}>
        <Row>
          <Col>
          { !product.tags.includes('Hide related products') &&
            <WearwithContainer>
              <WearWith meta={wear_with_meta_ids_base64} tags={product.tags} title={product.title} description={product.description} />
            </WearwithContainer>
          }
          </Col>
        </Row>
      </WearWithWrapper>
      <div></div>
    </Container>

  </ContainerProduct>
  );
}


export default ProductFull;
