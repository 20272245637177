import React, { Component, useContext } from 'react';
import styled from 'styled-components'
import { Link } from 'gatsby'
import { underscorify } from '../../utils'
import { Gemstones } from '../../utils/gemstones'
import StoreContext from '../../context/StoreContext'

const Gem = styled.div`
  picture {
    display: inline;
  }
  span {
    color: ${props => props.theme.link_color};
    text-decoration: none;
    float: right;
    color: #9C978F;
    font-size: 10px;
    cursor: pointer;
  }
  img {
    display: block;
    width: 8px !important;
    height: 8px !important;
    margin-right: 8px;
    margin-top: 2px;
    float: left;
  }
`

const ProductGemstone = (props) => {

    const { tags } = props;

    let gemstone;
    const context = useContext(StoreContext)
    const visible = context.gemOpen || false;

    const GemsWithVideos = Gemstones.filter(gem => gem.video !== undefined);

    let intersection = Gemstones.filter(gem => tags.includes(gem.name));

    gemstone = intersection[0];

    const toggleGem = (visible,name) => {
      context.toggleGem(visible, name);
    }

    // tags.map(tag => {
    //   Gemstones.map(gem => {
    //     if(gem.name === tag) {
    //       gemstone = gem;
    //     }
    //   })
    // })

    if(gemstone) {
      const gemIcon = require(`../../images/gemstones/${gemstone.image}`);
      return (
        <tr>
          <td className="label">Gemstone:</td>
          <td className="value">
            <Gem>
              <img src={gemIcon} alt={gemstone.name} />{gemstone.name}
              { gemstone.video &&
                <span onClick={() => toggleGem(true, gemstone.name)}>Read more</span>
              }
            </Gem>
          </td>
        </tr>
      );
    } else {
      return null;
    }

}

export default ProductGemstone;
