import React from 'react';
import { graphql } from 'gatsby';
import ProductFull from '../components/product_full'

const ProductPageTemplate = props => {
  const {
    shopifyProduct: product
  } = props.data;

  return (
        <>
          <ProductFull
            product={product}
          />
        </>
  );
};

export default ProductPageTemplate;

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      shopifyId
      title
      tags
      handle
      description
      productType
      metafields {
        value
        key
      }
      variants {
        shopifyId
        title
        price
        sku
        availableForSale
        image {
          originalSrc
        }
      }
      images {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
