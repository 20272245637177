import React, { Component } from 'react';
import styled from 'styled-components'
import ProductThumbnailPreview from '../product_thumbnail_preview'
import { Gemstones } from '../../utils/gemstones'

const Thumbnail = styled.div`
  display: flex;
  flex-basis: 30%;
  padding: 0px;
  transition: all 0.25s ease-in-out;
  @media (max-width: 768px) {
    .nomargin a div:last-child {
      display: none;
    }
    .gatsby-image-wrapper {
      display: block !important;
      height: 250px;
    }
  }
  h3 {
    opacity: 0;
  }
  &:hover {
    h3 {
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
   flex-basis: 48%;
   h3 {
     display: none;
   }
  }
`

class WearWithPicker extends Component {

  render() {

    const { products, title, description, tags, meta} = this.props;

    let gemstone;
    let similarProducts = [];
    let silvergoldProducts = [];
    let randomProducts = [];

    // check if tags on this product that's being viewed are valid gemstones
    tags.map(tag => {
      Gemstones.map(gem => {
        if(gem.name === tag) {
          gemstone = gem;
        }
      })
    })

    // if this product has been tagged with a valid gemstone, find others with the same gemstone
    if(gemstone) {
      products.sort(() => 0.5 - Math.random()).map((product, index) => {
        if(product.node.tags.includes(gemstone.name) &&
            product.node.title !== title &&
            !product.node.title.toLowerCase().includes('alphabet')) {
          similarProducts.push(product.node);
        }
      })
    }

    if(similarProducts.length < 3) { // like that silver pendant, no matching gemstones

      products.sort(() => 0.5 - Math.random()).map((product, index) => {

        if(
          product.node.description.toLowerCase().includes('silver') &&
          description.toLowerCase().includes('silver') &&
          !product.node.title.toLowerCase().includes('alphabet')) {
          silvergoldProducts.push(product.node);
        } else if(
          product.node.description.toLowerCase().includes('gold') &&
          description.toLowerCase().includes('gold') &&
          !product.node.title.toLowerCase().includes('alphabet')) {
          silvergoldProducts.push(product.node);

        }
      })
    }

    if(similarProducts.length + silvergoldProducts.length < 3) {
      products.sort(() => 0.5 - Math.random()).slice(0,3).map((product, index) => {
        randomProducts.push(product.node);
      })
    }

    let wearWith;

    if(meta === undefined || meta === false) {
      wearWith = [...similarProducts, ...silvergoldProducts, ...randomProducts].slice(0,3);
    } else if(meta && meta.length === 3) {

      const p1 = products.filter(p => p.node.shopifyId === meta[0]);
      const p2 = products.filter(p => p.node.shopifyId === meta[1]);
      const p3 = products.filter(p => p.node.shopifyId === meta[2]);

      let product_1, product_2, product_3 = false;

      if(p1.length > 0) {
        product_1 = products.filter(p => p.node.shopifyId === meta[0])[0].node;
      }

      if(p2.length > 0) {
        product_2 = products.filter(p => p.node.shopifyId === meta[1])[0].node;
      }

      if(p3.length > 0) {
        product_3 = products.filter(p => p.node.shopifyId === meta[2])[0].node;
      }

      wearWith = [product_1, product_2, product_3];

    }

    return(
      wearWith.map((product,index) => {
        if(product) {
          return(
            <Thumbnail className="thumbnail wearwith-thumbnail" key={index}>
              <ProductThumbnailPreview key={product.id} product={product} nomargin />
            </Thumbnail>
          )
        }
      })
    );
  }
}

export default WearWithPicker;
