/* just a small thumbnail of the product image + title + price, no description */

import React, { Component } from 'react';
import styled from 'styled-components'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #888;
  display: inline-block;
  margin-right: 10px;
`

const ProductName = styled.span`
  float: left;
  font-size: 11px;
  line-height: 14px;
  margin-top: 10px;
  max-width: 80%;
  font-weight: 400;
  span.original-price {
    margin-right: 0.5rem;
    opacity: 0.7;
    text-decoration: line-through;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
    font-size: 13px;
    line-height: 18px;
  }
`
const ProductPrice = styled.span`
  float: right;
  line-height: 14px;
  font-size: 11px;
  margin-top: 10px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
    float: left;
    clear: left;
    margin-bottom: 1rem;
  }
`
const ProductImage = styled.div`
  background-color: #fff;
  height: 100%;
  min-height: calc(100% - 100px);
  display: flex;
  align-items: center;
  width: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  img {
    background-color: #fff;
    transition: all 0.25s ease-in-out;
    filter: blur(8px);
    object-fit: contain !important;
  }
  picture {
    img {
      object-fit: contain !important;
      filter: blur(0px);
    }
  }
`

const ProductDetails = styled.div`
  min-height: 100px;
`

const ProductContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  width: 100%;
  flex: 1 0;
  align-items: space-between;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
  &.nomargin {
    margin-bottom: 0px;
  }
  a {
    width: 100%;
    color: ${props => props.theme.link_color};
  }
  picture {
    transition: opacity 0.4s ease-in-out;
  }
  .nohover {
    &:hover {
      picture {
        opacity: 1;
      }
    }
  }
  &:hover {
    picture {
      opacity: 0;
    }
  }
`

class ProductThumbnailPreview extends Component {
  render() {

    const {
      nomargin,
      product: {
        title,
        handle,
        variants: [firstVariant],
        images: [firstImage]
      }
    } = this.props;

    const { price, compareAtPrice } = firstVariant;

    const {
      localFile: {
        childImageSharp: { fluid }
      }
    } = firstImage;

    let hoverImage;
    let shouldhover;

    if(undefined !== this.props.product.images[2]) {
      hoverImage = this.props.product.images[2];
      shouldhover = true;
    } else if(undefined !== this.props.product.images[1]) {
      hoverImage = this.props.product.images[1];
      shouldhover = true;
    } else {
      shouldhover = false;
      hoverImage = this.props.product.images[0];
    }

    const url = `/${handle}`;

    return (
        <ProductContainer className={nomargin ? 'nomargin' : ''}>
          <Link to={url} state={{ shopOpen: false }}>
            <ProductImage>
              { hoverImage && shouldhover ? (
                <BackgroundImage fluid={hoverImage.localFile.childImageSharp.fluid}>
                  <Image className="initial" alt={handle} fluid={fluid} />
                </BackgroundImage>
              ) : (
                <Image className="nohover initial" alt={handle} fluid={fluid} />
              )}
            </ProductImage>
            <ProductDetails>
              <h3>
                <ProductName>{title}<br />
                  { compareAtPrice &&
                    <span class="original-price">${compareAtPrice}</span>
                  }
                  £{price}
                </ProductName>
              </h3>
            </ProductDetails>
          </Link>
        </ProductContainer>
    );
  }
}

export default ProductThumbnailPreview;
